/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Resource,
  ResourceID,
  ResourceIndex,
  ResourceRegionIndex,
  ResourceServiceIndex,
  ResourceTypeIndex,
} from "./resource_pb";
import { each, groupBy, isNil } from "lodash";
import { SpanScope } from "./span_pb";
import { getCurrentTime, Timestamp } from "./time";
import { projectSpan } from "./span";
import hash from "object-hash";

export type RegionCode = string;
export type ServiceCode = string;
export type ResourceTypeCode = string;
export type ResourceName = string;
export type ResourceScope = Pick<ResourceID, "region" | "service" | "type">;

export function getOrCreateRegion(idx: ResourceIndex, id: Pick<ResourceID, "region">): ResourceRegionIndex {
  let regionIdx = idx.byRegion[id.region];
  if (isNil(regionIdx)) {
    regionIdx = new ResourceRegionIndex({});
    idx.byRegion[id.region] = regionIdx;
  }
  return regionIdx;
}

export function getOrCreateService(
  idx: ResourceIndex,
  id: Pick<ResourceID, "region" | "service">
): ResourceServiceIndex {
  const regionIdx = getOrCreateRegion(idx, id);
  let serviceIdx = regionIdx.byService[id.service];
  if (isNil(serviceIdx)) {
    serviceIdx = new ResourceServiceIndex({});
    regionIdx.byService[id.service] = serviceIdx;
  }
  return serviceIdx;
}

export function getOrCreateType(
  idx: ResourceIndex,
  id: Pick<ResourceID, "region" | "service" | "type">
): ResourceTypeIndex {
  const serviceIdx = getOrCreateService(idx, id);
  let typeIdx = serviceIdx.byType[id.type];
  if (isNil(typeIdx)) {
    typeIdx = new ResourceTypeIndex({});
    serviceIdx.byType[id.type] = typeIdx;
  }
  return typeIdx;
}

export function getResource(
  idx: ResourceIndex,
  id: ResourceID,
  init: Partial<Resource> = {}
): Resource | null {
  const typeIdx = getOrCreateType(idx, id);
  let resource = typeIdx.byName[id.name];
  if (isNil(resource)) {
    resource = new Resource(init);
    typeIdx.byName[id.name] = resource;
  }
  return resource;
}

export function getOrCreate(idx: ResourceIndex, id: ResourceID, init: Partial<Resource> = {}): Resource {
  return getResource(idx, id, init)!;
}

export function idxForEach(idx: ResourceIndex, callback: (id: ResourceID, resource: Resource) => void) {
  each(idx.byRegion, (regions, regionCode: string) => {
    each(regions.byService, (services, serviceCode: string) => {
      each(services.byType, (types, resourceTypeCode: string) => {
        each(types.byName, (resource, name: string) => {
          callback(
            new ResourceID({ region: regionCode, service: serviceCode, type: resourceTypeCode, name }),
            resource
          );
        });
      });
    });
  });
}

export function toScopeString(id: Pick<ResourceID, "region" | "service" | "type">): string {
  return `${id.region}:${id.service}:${id.type}`;
}

export function toString(id: ResourceID): string {
  return `${id.region}:${id.service}:${id.type}:${id.name}`;
}

export function fromString(id: string): ResourceID {
  const [region, service, type, name] = id.split(":");
  return new ResourceID({ region, service, type, name });
}

export function toAttrs(id: ResourceID, resource: Resource): Record<string, string> {
  return {
    ...resource.refs,
    ...resource.specs,
    ...id,
  };
}

export type ResourceProjection = Pick<ResourceID, "region" | "service" | "type" | "name"> & {
  scope: SpanScope;
  specs: Record<string, unknown>;
  metrics: Record<string, number[]>;
  debits: Record<string, number[]>;
};

export function projection(
  scope: SpanScope,
  idx: ResourceIndex,
  timerange: number,
  currentTime: Timestamp = getCurrentTime()
): ResourceProjection[] {
  const result: ResourceProjection[] = [];
  idxForEach(idx, (id: ResourceID, resource: Resource) => {
    result.push({
      ...id,
      scope,
      specs: resource.specs,
      metrics: projectSpan(scope, resource.metrics, timerange, currentTime),
      debits: projectSpan(scope, resource.debits, timerange, currentTime),
    });
  });
  return result;
}

export function shouldScan(now: Timestamp, idx: Record<string, { scannedAt: number }>) {
  return (config: { inactive: boolean; scanInterval: number }, code: string) => {
    if (config.inactive) {
      return false; // don't scan if inactive
    }
    if (isNil(idx[code])) {
      return true; // scan if empty
    }
    return now - idx[code].scannedAt >= config.scanInterval; // scan if out of date
  };
}

// TODO: test
function createContext(idx: ResourceIndex, id: ResourceID) {
  const context: Record<string, object> = {};
  const resource = getOrCreate(idx, id);
  for (const ref in resource.refs) {
    const refID = new ResourceID({ ...id, name: resource.refs[ref] });
    const refResource = getOrCreate(idx, refID);
    context[ref] = toAttrs(refID, refResource);
  }
  return context;
}

export type ResourceGroup = {
  scope: ResourceScope;
  attrList: Record<string, unknown>[];
  idsByName: Record<ResourceName, ResourceID>;
  resourcesByName: Record<ResourceName, Resource>;
  context: Record<string, object>;
};

export function groupResources(idx: ResourceIndex, resources: ResourceID[]): ResourceGroup[] {
  const res: ResourceGroup[] = [];

  const resourcesByScope = Object.values(groupBy(resources, toScopeString));
  for (const scoped of resourcesByScope) {
    const scope = scoped[0];
    const resourcesByRef = Object.values(groupBy(scoped, (id) => hash(getOrCreate(idx, id).refs)));
    for (const grouped of resourcesByRef) {
      const resourcesByName: Record<string, Resource> = {};
      const attrList: Record<string, unknown>[] = [];
      const idsByName: Record<ResourceName, ResourceID> = {};
      for (const id of grouped) {
        const resource = getOrCreate(idx, id);
        resourcesByName[id.name] = resource;

        const attrs = toAttrs(id, new Resource({ ...resource, specs: {} })); // NOTE: this CANNOT use specs
        attrList.push(attrs);
        idsByName[id.name] = id;
      }
      const context = createContext(idx, grouped[0]);

      res.push({
        scope,
        attrList,
        idsByName,
        resourcesByName,
        context,
      });
    }
  }

  return res;
}
