import { Button, Card, Table } from "antd";
import { ColumnsType } from "antd/lib/table";

type RowType = {
  vendor: string;
  id: string;
  status: string;
};

const columns: ColumnsType<RowType> = [
  {
    title: "Vendor",
    dataIndex: "vendor",
    width: "25%",
  },
  {
    title: "ID",
    dataIndex: "id",
    width: "25%",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: "25%",
  },
  {
    title: "Actions",
    key: "action",
    width: "15%",
    render: () => (
      <div className="text-center">
        <a href="#">Remove</a>
      </div>
    ),
  },
];

const rows: RowType[] = [
  {
    vendor: "AWS",
    id: "12712122941",
    status: "Active",
  },
];

const Accounts = () => (
  <>
    <div className="flex justify-end mb-6 ">
      <Button type="primary" className="shadow">
        Add Account
      </Button>
    </div>

    <Table columns={columns} dataSource={rows} bordered pagination={false} />
  </>
);

export default Accounts;
