// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file config.proto (package cloudwhistle, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { SpanScope } from "./span_pb.js";
import { ResourceDetailLookup, ResourceListLookup, ResourceUsageLookup } from "./resource_pb.js";
import { PriceLookup } from "./price_pb.js";
import { Selector } from "./selector_pb.js";
import { DebitCalc } from "./debit_pb.js";
import { RuleSet } from "./rule_pb.js";
import { ActionTemplate } from "./action_pb.js";

/**
 * @generated from message cloudwhistle.Config
 */
export class Config extends Message<Config> {
  /**
   * @generated from field: cloudwhistle.ResourcesConfig resources = 1;
   */
  resources?: ResourcesConfig;

  /**
   * @generated from field: cloudwhistle.RulesConfig rules = 2;
   */
  rules?: RulesConfig;

  /**
   * @generated from field: cloudwhistle.ReportsConfig reports = 3;
   */
  reports?: ReportsConfig;

  /**
   * (optional) arbitrary meta data
   *
   * @generated from field: map<string, string> metadata = 99;
   */
  metadata: { [key: string]: string } = {};

  constructor(data?: PartialMessage<Config>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.Config";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resources", kind: "message", T: ResourcesConfig },
    { no: 2, name: "rules", kind: "message", T: RulesConfig },
    { no: 3, name: "reports", kind: "message", T: ReportsConfig },
    {
      no: 99,
      name: "metadata",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "scalar", T: 9 /* ScalarType.STRING */ },
    },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Config {
    return new Config().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Config {
    return new Config().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Config {
    return new Config().fromJsonString(jsonString, options);
  }

  static equals(
    a: Config | PlainMessage<Config> | undefined,
    b: Config | PlainMessage<Config> | undefined
  ): boolean {
    return proto3.util.equals(Config, a, b);
  }
}

/**
 * @generated from message cloudwhistle.ResourcesConfig
 */
export class ResourcesConfig extends Message<ResourcesConfig> {
  /**
   * index of regions that are monitored
   *
   * @generated from field: map<string, cloudwhistle.RegionConfig> regions = 1;
   */
  regions: { [key: string]: RegionConfig } = {};

  /**
   * index of services that are monitored
   *
   * @generated from field: map<string, cloudwhistle.ServiceConfig> services = 2;
   */
  services: { [key: string]: ServiceConfig } = {};

  /**
   * index of metric scopes to track,
   * indexed by range (ie total seconds covered by span)
   *
   * @generated from field: map<string, cloudwhistle.SpanScope> scopes = 3;
   */
  scopes: { [key: string]: SpanScope } = {};

  /**
   * limit of how many regions should be scanned at the same time
   *
   * @generated from field: uint32 maxConcurrentScans = 4;
   */
  maxConcurrentScans = 0;

  /**
   * limit of how many resources can be looked up at the same time
   *
   * @generated from field: uint32 maxConcurrentLookups = 5;
   */
  maxConcurrentLookups = 0;

  /**
   * duration, in minutes, after which a resource spec will be considered expired
   *
   * @generated from field: uint32 resourceExpiration = 6;
   */
  resourceExpiration = 0;

  /**
   * timestamp of when the config was updated last
   *
   * @generated from field: uint32 updatedAt = 7;
   */
  updatedAt = 0;

  /**
   * throttles per API resource (e.g. `ECS.describeInstances` -> 5)
   *
   * @generated from field: map<string, uint32> throttles = 8;
   */
  throttles: { [key: string]: number } = {};

  /**
   * (optional) arbitrary meta data
   *
   * @generated from field: map<string, string> metadata = 99;
   */
  metadata: { [key: string]: string } = {};

  constructor(data?: PartialMessage<ResourcesConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.ResourcesConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: "regions",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "message", T: RegionConfig },
    },
    {
      no: 2,
      name: "services",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "message", T: ServiceConfig },
    },
    {
      no: 3,
      name: "scopes",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "message", T: SpanScope },
    },
    { no: 4, name: "maxConcurrentScans", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "maxConcurrentLookups", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 6, name: "resourceExpiration", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 7, name: "updatedAt", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    {
      no: 8,
      name: "throttles",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    },
    {
      no: 99,
      name: "metadata",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "scalar", T: 9 /* ScalarType.STRING */ },
    },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcesConfig {
    return new ResourcesConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcesConfig {
    return new ResourcesConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcesConfig {
    return new ResourcesConfig().fromJsonString(jsonString, options);
  }

  static equals(
    a: ResourcesConfig | PlainMessage<ResourcesConfig> | undefined,
    b: ResourcesConfig | PlainMessage<ResourcesConfig> | undefined
  ): boolean {
    return proto3.util.equals(ResourcesConfig, a, b);
  }
}

/**
 * @generated from message cloudwhistle.RegionConfig
 */
export class RegionConfig extends Message<RegionConfig> {
  /**
   * indicates whether this region is NOT actively monitored
   *
   * @generated from field: bool inactive = 1;
   */
  inactive = false;

  /**
   * time, in minutes, after which this region should be scanned again
   *
   * @generated from field: uint32 scanInterval = 2;
   */
  scanInterval = 0;

  constructor(data?: PartialMessage<RegionConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.RegionConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "inactive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "scanInterval", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RegionConfig {
    return new RegionConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RegionConfig {
    return new RegionConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RegionConfig {
    return new RegionConfig().fromJsonString(jsonString, options);
  }

  static equals(
    a: RegionConfig | PlainMessage<RegionConfig> | undefined,
    b: RegionConfig | PlainMessage<RegionConfig> | undefined
  ): boolean {
    return proto3.util.equals(RegionConfig, a, b);
  }
}

/**
 * @generated from message cloudwhistle.ServiceConfig
 */
export class ServiceConfig extends Message<ServiceConfig> {
  /**
   * indicates whether this service is NOT actively monitored
   *
   * @generated from field: bool inactive = 1;
   */
  inactive = false;

  /**
   * index of resource types that are monitored
   *
   * @generated from field: map<string, cloudwhistle.ResourceTypeConfig> resourceTypes = 2;
   */
  resourceTypes: { [key: string]: ResourceTypeConfig } = {};

  /**
   * identifier for looking up the service's pricing
   *
   * @generated from field: string pricingCode = 3;
   */
  pricingCode = "";

  /**
   * identifier for lookup up the service's metrics
   *
   * @generated from field: string metricsCode = 4;
   */
  metricsCode = "";

  /**
   * time, in minutes, after a service should be scanned again
   *
   * @generated from field: uint32 scanInterval = 5;
   */
  scanInterval = 0;

  constructor(data?: PartialMessage<ServiceConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.ServiceConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "inactive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    {
      no: 2,
      name: "resourceTypes",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "message", T: ResourceTypeConfig },
    },
    { no: 3, name: "pricingCode", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "metricsCode", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "scanInterval", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ServiceConfig {
    return new ServiceConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ServiceConfig {
    return new ServiceConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ServiceConfig {
    return new ServiceConfig().fromJsonString(jsonString, options);
  }

  static equals(
    a: ServiceConfig | PlainMessage<ServiceConfig> | undefined,
    b: ServiceConfig | PlainMessage<ServiceConfig> | undefined
  ): boolean {
    return proto3.util.equals(ServiceConfig, a, b);
  }
}

/**
 * @generated from message cloudwhistle.ResourceTypeConfig
 */
export class ResourceTypeConfig extends Message<ResourceTypeConfig> {
  /**
   * indicates whether this resource type is NOT actively monitored
   *
   * @generated from field: bool inactive = 1;
   */
  inactive = false;

  /**
   * time, in minutes, after a service should be scanned again
   *
   * @generated from field: uint32 scanInterval = 2;
   */
  scanInterval = 0;

  /**
   * relationships to other types
   *
   * @generated from field: map<string, string> relationships = 3;
   */
  relationships: { [key: string]: string } = {};

  /**
   * @generated from field: cloudwhistle.ResourceTypeScanConfig scanConfig = 4;
   */
  scanConfig?: ResourceTypeScanConfig;

  /**
   * @generated from field: cloudwhistle.ResourceTypeSpecConfig specConfig = 5;
   */
  specConfig?: ResourceTypeSpecConfig;

  /**
   * @generated from field: cloudwhistle.ResourceTypeMetricConfig metricConfig = 6;
   */
  metricConfig?: ResourceTypeMetricConfig;

  /**
   * @generated from field: cloudwhistle.ResourceTypePriceConfig priceConfig = 7;
   */
  priceConfig?: ResourceTypePriceConfig;

  /**
   * @generated from field: cloudwhistle.ResourceTypeDebitConfig debitConfig = 8;
   */
  debitConfig?: ResourceTypeDebitConfig;

  constructor(data?: PartialMessage<ResourceTypeConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.ResourceTypeConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "inactive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "scanInterval", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    {
      no: 3,
      name: "relationships",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "scalar", T: 9 /* ScalarType.STRING */ },
    },
    { no: 4, name: "scanConfig", kind: "message", T: ResourceTypeScanConfig },
    { no: 5, name: "specConfig", kind: "message", T: ResourceTypeSpecConfig },
    { no: 6, name: "metricConfig", kind: "message", T: ResourceTypeMetricConfig },
    { no: 7, name: "priceConfig", kind: "message", T: ResourceTypePriceConfig },
    { no: 8, name: "debitConfig", kind: "message", T: ResourceTypeDebitConfig },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceTypeConfig {
    return new ResourceTypeConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceTypeConfig {
    return new ResourceTypeConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceTypeConfig {
    return new ResourceTypeConfig().fromJsonString(jsonString, options);
  }

  static equals(
    a: ResourceTypeConfig | PlainMessage<ResourceTypeConfig> | undefined,
    b: ResourceTypeConfig | PlainMessage<ResourceTypeConfig> | undefined
  ): boolean {
    return proto3.util.equals(ResourceTypeConfig, a, b);
  }
}

/**
 * @generated from message cloudwhistle.ResourceTypeScanConfig
 */
export class ResourceTypeScanConfig extends Message<ResourceTypeScanConfig> {
  /**
   * [for when the cloud requires querying each type indididually]
   * instructions on how to query the resource IDs for this resource type
   *
   * @generated from field: cloudwhistle.ResourceListLookup lookup = 1;
   */
  lookup?: ResourceListLookup;

  /**
   * [for when the cloud supports listing resources]
   * lookup identifier for scanning for new resources of this kind
   *
   * @generated from field: string scanCode = 2;
   */
  scanCode = "";

  /**
   * @generated from field: string nameRegex = 3;
   */
  nameRegex = "";

  constructor(data?: PartialMessage<ResourceTypeScanConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.ResourceTypeScanConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lookup", kind: "message", T: ResourceListLookup },
    { no: 2, name: "scanCode", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "nameRegex", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceTypeScanConfig {
    return new ResourceTypeScanConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceTypeScanConfig {
    return new ResourceTypeScanConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceTypeScanConfig {
    return new ResourceTypeScanConfig().fromJsonString(jsonString, options);
  }

  static equals(
    a: ResourceTypeScanConfig | PlainMessage<ResourceTypeScanConfig> | undefined,
    b: ResourceTypeScanConfig | PlainMessage<ResourceTypeScanConfig> | undefined
  ): boolean {
    return proto3.util.equals(ResourceTypeScanConfig, a, b);
  }
}

/**
 * @generated from message cloudwhistle.ResourceTypeSpecConfig
 */
export class ResourceTypeSpecConfig extends Message<ResourceTypeSpecConfig> {
  /**
   * instructions on how to lookup the resource specs for this resource type
   *
   * @generated from field: repeated cloudwhistle.ResourceDetailLookup lookups = 1;
   */
  lookups: ResourceDetailLookup[] = [];

  /**
   * time, in minutes, after the specs of a resource of this type should be refreshed
   *
   * @generated from field: uint32 refreshInterval = 2;
   */
  refreshInterval = 0;

  /**
   * limit of how many resources of this type can be looked up at the same time
   *
   * @generated from field: uint32 maxConcurrency = 3;
   */
  maxConcurrency = 0;

  constructor(data?: PartialMessage<ResourceTypeSpecConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.ResourceTypeSpecConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lookups", kind: "message", T: ResourceDetailLookup, repeated: true },
    { no: 2, name: "refreshInterval", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "maxConcurrency", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceTypeSpecConfig {
    return new ResourceTypeSpecConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceTypeSpecConfig {
    return new ResourceTypeSpecConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceTypeSpecConfig {
    return new ResourceTypeSpecConfig().fromJsonString(jsonString, options);
  }

  static equals(
    a: ResourceTypeSpecConfig | PlainMessage<ResourceTypeSpecConfig> | undefined,
    b: ResourceTypeSpecConfig | PlainMessage<ResourceTypeSpecConfig> | undefined
  ): boolean {
    return proto3.util.equals(ResourceTypeSpecConfig, a, b);
  }
}

/**
 * @generated from message cloudwhistle.ResourceTypePriceConfig
 */
export class ResourceTypePriceConfig extends Message<ResourceTypePriceConfig> {
  /**
   * filters for querying the pricing info for this resource type
   *
   * @generated from field: map<string, string> filters = 1;
   */
  filters: { [key: string]: string } = {};

  /**
   * instructions on how to lookup the price points for this resource type
   *
   * @generated from field: map<string, cloudwhistle.PriceLookup> lookups = 2;
   */
  lookups: { [key: string]: PriceLookup } = {};

  /**
   * time, in minutes, after the pricing of a resource of this type should be refreshed
   *
   * @generated from field: uint32 refreshInterval = 3;
   */
  refreshInterval = 0;

  /**
   * selectors for condition inputs
   *
   * @generated from field: map<string, cloudwhistle.Selector> inputs = 4;
   */
  inputs: { [key: string]: Selector } = {};

  constructor(data?: PartialMessage<ResourceTypePriceConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.ResourceTypePriceConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: "filters",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "scalar", T: 9 /* ScalarType.STRING */ },
    },
    {
      no: 2,
      name: "lookups",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "message", T: PriceLookup },
    },
    { no: 3, name: "refreshInterval", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "inputs", kind: "map", K: 9 /* ScalarType.STRING */, V: { kind: "message", T: Selector } },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceTypePriceConfig {
    return new ResourceTypePriceConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceTypePriceConfig {
    return new ResourceTypePriceConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceTypePriceConfig {
    return new ResourceTypePriceConfig().fromJsonString(jsonString, options);
  }

  static equals(
    a: ResourceTypePriceConfig | PlainMessage<ResourceTypePriceConfig> | undefined,
    b: ResourceTypePriceConfig | PlainMessage<ResourceTypePriceConfig> | undefined
  ): boolean {
    return proto3.util.equals(ResourceTypePriceConfig, a, b);
  }
}

/**
 * @generated from message cloudwhistle.ResourceTypeMetricConfig
 */
export class ResourceTypeMetricConfig extends Message<ResourceTypeMetricConfig> {
  /**
   * instructions on how to lookup the metrics for this resource type
   *
   * @generated from field: map<string, cloudwhistle.ResourceUsageLookup> usageLookups = 1;
   */
  usageLookups: { [key: string]: ResourceUsageLookup } = {};

  /**
   * instructions on how to query resource statuses for this resource type
   *
   * @generated from field: repeated cloudwhistle.ResourceDetailLookup statusLookups = 2;
   */
  statusLookups: ResourceDetailLookup[] = [];

  /**
   * time, in minutes, after the metrics of a resource of this type should be refreshed
   *
   * @generated from field: uint32 refreshInterval = 3;
   */
  refreshInterval = 0;

  constructor(data?: PartialMessage<ResourceTypeMetricConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.ResourceTypeMetricConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: "usageLookups",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "message", T: ResourceUsageLookup },
    },
    { no: 2, name: "statusLookups", kind: "message", T: ResourceDetailLookup, repeated: true },
    { no: 3, name: "refreshInterval", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceTypeMetricConfig {
    return new ResourceTypeMetricConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceTypeMetricConfig {
    return new ResourceTypeMetricConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceTypeMetricConfig {
    return new ResourceTypeMetricConfig().fromJsonString(jsonString, options);
  }

  static equals(
    a: ResourceTypeMetricConfig | PlainMessage<ResourceTypeMetricConfig> | undefined,
    b: ResourceTypeMetricConfig | PlainMessage<ResourceTypeMetricConfig> | undefined
  ): boolean {
    return proto3.util.equals(ResourceTypeMetricConfig, a, b);
  }
}

/**
 * @generated from message cloudwhistle.ResourceTypeDebitConfig
 */
export class ResourceTypeDebitConfig extends Message<ResourceTypeDebitConfig> {
  /**
   * instructions on how to calculate the debits for this resource type
   *
   * @generated from field: map<string, cloudwhistle.DebitCalc> calculations = 1;
   */
  calculations: { [key: string]: DebitCalc } = {};

  constructor(data?: PartialMessage<ResourceTypeDebitConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.ResourceTypeDebitConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: "calculations",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "message", T: DebitCalc },
    },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceTypeDebitConfig {
    return new ResourceTypeDebitConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceTypeDebitConfig {
    return new ResourceTypeDebitConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceTypeDebitConfig {
    return new ResourceTypeDebitConfig().fromJsonString(jsonString, options);
  }

  static equals(
    a: ResourceTypeDebitConfig | PlainMessage<ResourceTypeDebitConfig> | undefined,
    b: ResourceTypeDebitConfig | PlainMessage<ResourceTypeDebitConfig> | undefined
  ): boolean {
    return proto3.util.equals(ResourceTypeDebitConfig, a, b);
  }
}

/**
 * @generated from message cloudwhistle.ReportsConfig
 */
export class ReportsConfig extends Message<ReportsConfig> {
  /**
   * TODO
   * time, in minutes, after which a heartbeat should be sent to the server
   *
   * @generated from field: uint32 heartbeatInterval = 1;
   */
  heartbeatInterval = 0;

  /**
   * time, in milliseconds, after which a pending heartbeat is considered to have failed
   *
   * @generated from field: uint32 heartbeatTimeout = 2;
   */
  heartbeatTimeout = 0;

  /**
   * time, in milliseconds, after which a pending heartbeat is considered to have failed
   *
   * @generated from field: uint32 heartbeatRetries = 3;
   */
  heartbeatRetries = 0;

  /**
   * list of endpoints to send heartbeats to
   *
   * @generated from field: repeated string heartbeatEndpoints = 4;
   */
  heartbeatEndpoints: string[] = [];

  /**
   * list of endpoints to send errors to
   *
   * @generated from field: repeated string errorEndpoints = 5;
   */
  errorEndpoints: string[] = [];

  /**
   * (optional) arbitrary meta data
   *
   * @generated from field: map<string, string> metadata = 99;
   */
  metadata: { [key: string]: string } = {};

  constructor(data?: PartialMessage<ReportsConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.ReportsConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "heartbeatInterval", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "heartbeatTimeout", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "heartbeatRetries", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "heartbeatEndpoints", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "errorEndpoints", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    {
      no: 99,
      name: "metadata",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "scalar", T: 9 /* ScalarType.STRING */ },
    },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReportsConfig {
    return new ReportsConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReportsConfig {
    return new ReportsConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReportsConfig {
    return new ReportsConfig().fromJsonString(jsonString, options);
  }

  static equals(
    a: ReportsConfig | PlainMessage<ReportsConfig> | undefined,
    b: ReportsConfig | PlainMessage<ReportsConfig> | undefined
  ): boolean {
    return proto3.util.equals(ReportsConfig, a, b);
  }
}

/**
 * @generated from message cloudwhistle.RulesConfig
 */
export class RulesConfig extends Message<RulesConfig> {
  /**
   * index of rule sets; they decide whether to trigger an action
   *
   * @generated from field: map<string, cloudwhistle.RuleSet> ruleSets = 1;
   */
  ruleSets: { [key: string]: RuleSet } = {};

  /**
   * index of action templates
   *
   * @generated from field: map<string, cloudwhistle.ActionTemplate> actionTemplates = 2;
   */
  actionTemplates: { [key: string]: ActionTemplate } = {};

  /**
   * (optional) arbitrary meta data
   *
   * @generated from field: map<string, string> metadata = 99;
   */
  metadata: { [key: string]: string } = {};

  constructor(data?: PartialMessage<RulesConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.RulesConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: "ruleSets",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "message", T: RuleSet },
    },
    {
      no: 2,
      name: "actionTemplates",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "message", T: ActionTemplate },
    },
    {
      no: 99,
      name: "metadata",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "scalar", T: 9 /* ScalarType.STRING */ },
    },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RulesConfig {
    return new RulesConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RulesConfig {
    return new RulesConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RulesConfig {
    return new RulesConfig().fromJsonString(jsonString, options);
  }

  static equals(
    a: RulesConfig | PlainMessage<RulesConfig> | undefined,
    b: RulesConfig | PlainMessage<RulesConfig> | undefined
  ): boolean {
    return proto3.util.equals(RulesConfig, a, b);
  }
}
