import { useEffect } from "react";
import { fetchResources, Resources, fetchResourcesConf, ResourcesConf } from "../../store/resources";
import { LoadingState } from "../../store/util";
import { ResourceTable } from "./dashboard";

const Home = () => {
  const resources = Resources.useContainer();
  useEffect(() => {
    if (resources.loadingState == LoadingState.NONE) {
      resources.setLoadingState(LoadingState.LOADING);
      fetchResources()
        .then((res) => {
          resources.setLoadingState(LoadingState.LOADED);
          resources.setIndex(res);
        })
        .catch((err) => {
          resources.setLoadingState(LoadingState.ERROR);
          console.error(err);
        });
    }
  }, [resources.loadingState]);

  const conf = ResourcesConf.useContainer();
  useEffect(() => {
    if (conf.loadingState == LoadingState.NONE) {
      conf.setLoadingState(LoadingState.LOADING);
      fetchResourcesConf()
        .then((res) => {
          conf.setLoadingState(LoadingState.LOADED);
          conf.setConfig(res);
        })
        .catch((err) => {
          conf.setLoadingState(LoadingState.ERROR);
          console.error(err);
        });
    }
  }, [conf.loadingState]);

  const resetLoad = () => {
    resources.setLoadingState(LoadingState.NONE);
    conf.setLoadingState(LoadingState.NONE);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      resetLoad();
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    return () => {
      resetLoad();
      resources.setIndex(undefined);
      conf.setConfig(undefined);
    };
  }, []);

  return <ResourceTable serviceConfs={conf.config?.services} idx={resources.index} />;
};

export default Home;
