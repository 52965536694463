import { Button, Card, InputNumber, Select, Tooltip } from "antd";
import { ReactNode, useState } from "react";
import {
  InformationCircleIcon,
  EnvelopeIcon,
  PlusCircleIcon as PlusCircleOutlineIcon,
} from "@heroicons/react/24/outline";
import { PlusCircleIcon as PlusCircleSolidIcon } from "@heroicons/react/24/solid";

import "./index.css";

type Condition = {};

type Action = {};

type Rule = {
  conditions: Condition[];
  actions: Action[];
};

const Item = ({
  rule,
  selected,
  editRule,
}: {
  rule: Rule;
  selected: boolean;
  editRule: (rule: Rule) => void;
}) => (
  <div
    className={
      "rule-item p-2 mb-1 bg-white shadow hover:bg-gray-50 cursor-pointer " +
      (selected ? "border border-black border-dashed border-purple-400" : "")
    }
    onClick={() => editRule(rule)}
  >
    <div className="flex flex-row">
      <div className="py-3">
        <When>
          <Condition>total spending is above $1 over the last 1 hour(s) in any account</Condition>
        </When>
        <Then>
          <Action>
            send an alert to
            <ActionLabel>
              <EnvelopeIcon height={18} width={18} className="inline-block mr-1" />
              account@domain.com
            </ActionLabel>
          </Action>
        </Then>
      </div>
    </div>
  </div>
);

const When = ({ children }: { children: ReactNode }) => (
  <div className="flex flex-row">
    <div className="w-16 px-3 py-1 font-bold uppercase">When</div>
    <div className="py-1 pl-2">{children}</div>
  </div>
);

const Then = ({ children }: { children: ReactNode }) => (
  <div className="flex flex-row">
    <div className="w-16 px-3 py-1 font-bold uppercase">Then</div>
    <div className="pt-1 pl-2">{children}</div>
  </div>
);

const Condition = ({ children }: { children: ReactNode }) => <div>{children}</div>;

const Action = ({ children }: { children: ReactNode }) => <div>{children}</div>;

const ActionLabel = ({ children }: { children: ReactNode }) => (
  <span className="text-xs font-semibold inline-block py-1 px-2 rounded-full text-purple-600 bg-purple-200 ml-1">
    {children}
  </span>
);

const Connector = () => (
  <div className="relative mb-16">
    <div className="absolute left-1/2 h-7 border border-dashed border-slate-400"></div>
    <div className="absolute left-1/2 mt-7" style={{ marginLeft: -16 }}>
      <Tooltip title={"We'll add the ability to add more rules soon!"}>
        <div className="rule-add cursor-pointer">
          <PlusCircleOutlineIcon width={34} height={34} className="inactive" />
          <PlusCircleSolidIcon width={34} height={34} className="active hidden" />
        </div>
      </Tooltip>
    </div>
  </div>
);

const RuleList = ({ rules, editRule }: { rules: Rule[]; editRule: (rule: Rule) => void }) => (
  <div className="leading-8">
    {rules.map((r) => (
      <Item rule={r} selected={false} editRule={editRule} />
    ))}
    <Connector />
  </div>
);

const RuleDetails = ({
  cancel,
}: // save
{
  cancel: () => void;
  // save: (rule: Rule) => void
}) => (
  <Card className="shadow">
    <div className="flex justify-between align-middle p-3 -m-6 mb-6 border-b">
      <h2 className="font-semibold text-lg m-0">When</h2>
      <div>
        <Tooltip title="The condition of the rule determines when it matches.">
          <InformationCircleIcon className="text-purple-800 cursor-pointer" width={24} height={24} />
        </Tooltip>
      </div>
    </div>

    <ul className="list-disc ml-2 leading-10">
      <li>
        <Select
          defaultValue="total"
          disabled
          options={[
            {
              value: "total",
              label: "total spending",
            },
          ]}
        />

        <span className="p-2">is over</span>

        <InputNumber
          defaultValue={1}
          min={0.001}
          max={10000}
          formatter={(value) => "$" + `${value}`}
          // parser={value => value!.replace('$', '')}
        />

        <span className="p-2">over the last</span>

        <InputNumber defaultValue={1} min={1} max={24} />

        <span className="p-2">hour(s)</span>
      </li>

      <li>
        <span className="pr-2">cloud account is</span>

        <Select
          defaultValue="any"
          disabled
          options={[
            {
              value: "any",
              label: "any",
            },
          ]}
        />
      </li>
    </ul>

    <div className="flex justify-between align-middle p-3 -m-6 mt-9 mb-6 border-y">
      <h2 className="font-semibold text-lg m-0">Then</h2>
      <div>
        <Tooltip title="The action(s) that should be performed when the rule matches.">
          <InformationCircleIcon className="text-purple-800 cursor-pointer" width={24} height={24} />
        </Tooltip>
      </div>
    </div>

    <ul className="ml-2 list-disc">
      <li>
        <span className="pr-2">send an alert to</span>

        <Select
          defaultValue="integration0"
          options={[
            {
              value: "integration0",
              label: "account@domain.com",
            },
          ]}
        />
      </li>
    </ul>

    <div className="flex justify-end space-x-4 px-3 py-3 -m-6 mt-9 border-t">
      <Button onClick={() => cancel()}>Cancel</Button>
      <Button type="primary" onClick={() => cancel()}>
        Save
      </Button>
    </div>
  </Card>
);

const Rules = () => {
  const rules: Rule[] = [
    {
      conditions: [],
      actions: [],
    },
  ];
  const [ruleEdit, setRuleEdit] = useState<Rule | undefined>();

  return (
    <div className="grid grid-cols-2 gap-8 pb-1">
      <RuleList rules={rules} editRule={setRuleEdit} />
      {ruleEdit ? <RuleDetails cancel={() => setRuleEdit(undefined)} /> : <></>}
    </div>
  );
};

export default Rules;
