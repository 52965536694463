import { Outlet } from "react-router-dom";
import { map } from "lodash";
import { Link } from "react-router-dom";

const Layout = () => (
  <div className="font-sans w-full min-h-screen flex flex-col bg-gray-100">
    <nav className="bg-white shadow mb-6">
      <div className="mx-auto">
        <div className="relative flex h-12 items-center">
          <div className="flex flex-1 items-center justify-center">
            <div className="">
              <div className="flex space-x-4 ">
                {map(
                  {
                    Overview: "/",
                    Accounts: "/accounts",
                    Integrations: "/integrations",
                    Rules: "/rules",
                    Settings: "/settings",
                  },
                  (route, label) => (
                    <Link
                      to={route}
                      key={route}
                      className={
                        "px-3 py-2 rounded-md text-sm font-medium font-semibold " +
                        (location.pathname === route ? "" : "text-gray-800")
                      }
                    >
                      {label}
                    </Link>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div className="flex-1 flex flex-col sm:flex-row">
      <main className="flex-1">
        <div className="App mb-6">
          <div className="overflow-x-auto">
            <Outlet />
          </div>
        </div>
      </main>

      <nav className="order-first sm:w-48"></nav>
      <aside className="sm:w-48"></aside>
    </div>
  </div>
);

export default Layout;
