// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file price.proto (package cloudwhistle, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Unit } from "./unit_pb.js";

/**
 * @generated from message cloudwhistle.PricePoint
 */
export class PricePoint extends Message<PricePoint> {
  /**
   * @generated from field: double price = 1;
   */
  price = 0;

  /**
   * number of prices that were averaged to get the final price
   *
   * @generated from field: uint32 sources = 2;
   */
  sources = 0;

  constructor(data?: PartialMessage<PricePoint>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.PricePoint";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "sources", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PricePoint {
    return new PricePoint().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PricePoint {
    return new PricePoint().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PricePoint {
    return new PricePoint().fromJsonString(jsonString, options);
  }

  static equals(
    a: PricePoint | PlainMessage<PricePoint> | undefined,
    b: PricePoint | PlainMessage<PricePoint> | undefined
  ): boolean {
    return proto3.util.equals(PricePoint, a, b);
  }
}

/**
 * @generated from message cloudwhistle.PriceLookup
 */
export class PriceLookup extends Message<PriceLookup> {
  /**
   * list of variants for the price lookup
   *
   * @generated from field: repeated cloudwhistle.PriceVariant variants = 1;
   */
  variants: PriceVariant[] = [];

  /**
   * (optional) unit of time for this price
   *
   * @generated from field: cloudwhistle.Unit timeUnit = 2;
   */
  timeUnit = Unit.None;

  /**
   * (optional) unit of size for this price
   *
   * @generated from field: cloudwhistle.Unit sizeUnit = 3;
   */
  sizeUnit = Unit.None;

  constructor(data?: PartialMessage<PriceLookup>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.PriceLookup";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "variants", kind: "message", T: PriceVariant, repeated: true },
    { no: 2, name: "timeUnit", kind: "enum", T: proto3.getEnumType(Unit) },
    { no: 3, name: "sizeUnit", kind: "enum", T: proto3.getEnumType(Unit) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PriceLookup {
    return new PriceLookup().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PriceLookup {
    return new PriceLookup().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PriceLookup {
    return new PriceLookup().fromJsonString(jsonString, options);
  }

  static equals(
    a: PriceLookup | PlainMessage<PriceLookup> | undefined,
    b: PriceLookup | PlainMessage<PriceLookup> | undefined
  ): boolean {
    return proto3.util.equals(PriceLookup, a, b);
  }
}

/**
 * @generated from message cloudwhistle.PriceVariant
 */
export class PriceVariant extends Message<PriceVariant> {
  /**
   * list of conditions for the resource - this is used to select a price variant
   *
   * @generated from field: repeated string resourceConditions = 1;
   */
  resourceConditions: string[] = [];

  /**
   * list of conditions for the product - this is used to find the correct product
   *
   * @generated from field: repeated string productConditions = 2;
   */
  productConditions: string[] = [];

  constructor(data?: PartialMessage<PriceVariant>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.PriceVariant";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resourceConditions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "productConditions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PriceVariant {
    return new PriceVariant().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PriceVariant {
    return new PriceVariant().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PriceVariant {
    return new PriceVariant().fromJsonString(jsonString, options);
  }

  static equals(
    a: PriceVariant | PlainMessage<PriceVariant> | undefined,
    b: PriceVariant | PlainMessage<PriceVariant> | undefined
  ): boolean {
    return proto3.util.equals(PriceVariant, a, b);
  }
}

/**
 * @generated from message cloudwhistle.Product
 */
export class Product extends Message<Product> {
  /**
   * @generated from field: map<string, string> attrs = 1;
   */
  attrs: { [key: string]: string } = {};

  /**
   * @generated from field: repeated cloudwhistle.ProductPrice prices = 2;
   */
  prices: ProductPrice[] = [];

  constructor(data?: PartialMessage<Product>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.Product";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: "attrs",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "scalar", T: 9 /* ScalarType.STRING */ },
    },
    { no: 2, name: "prices", kind: "message", T: ProductPrice, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Product {
    return new Product().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Product {
    return new Product().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Product {
    return new Product().fromJsonString(jsonString, options);
  }

  static equals(
    a: Product | PlainMessage<Product> | undefined,
    b: Product | PlainMessage<Product> | undefined
  ): boolean {
    return proto3.util.equals(Product, a, b);
  }
}

/**
 * @generated from message cloudwhistle.ProductPrice
 */
export class ProductPrice extends Message<ProductPrice> {
  /**
   * @generated from field: double usd = 1;
   */
  usd = 0;

  constructor(data?: PartialMessage<ProductPrice>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.ProductPrice";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "usd", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductPrice {
    return new ProductPrice().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductPrice {
    return new ProductPrice().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductPrice {
    return new ProductPrice().fromJsonString(jsonString, options);
  }

  static equals(
    a: ProductPrice | PlainMessage<ProductPrice> | undefined,
    b: ProductPrice | PlainMessage<ProductPrice> | undefined
  ): boolean {
    return proto3.util.equals(ProductPrice, a, b);
  }
}
