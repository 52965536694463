// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file unit.proto (package cloudwhistle, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum cloudwhistle.Unit
 */
export enum Unit {
  /**
   * @generated from enum value: None = 0;
   */
  None = 0,

  /**
   * @generated from enum value: Count = 1;
   */
  Count = 1,

  /**
   * @generated from enum value: Percent = 2;
   */
  Percent = 2,

  /**
   * @generated from enum value: Byte = 3;
   */
  Byte = 3,

  /**
   * @generated from enum value: Kilobyte = 4;
   */
  Kilobyte = 4,

  /**
   * @generated from enum value: Megabyte = 5;
   */
  Megabyte = 5,

  /**
   * @generated from enum value: Gigabyte = 6;
   */
  Gigabyte = 6,

  /**
   * @generated from enum value: Terabyte = 7;
   */
  Terabyte = 7,

  /**
   * @generated from enum value: Millisecond = 8;
   */
  Millisecond = 8,

  /**
   * @generated from enum value: Second = 9;
   */
  Second = 9,

  /**
   * @generated from enum value: Minute = 10;
   */
  Minute = 10,

  /**
   * @generated from enum value: Hour = 11;
   */
  Hour = 11,

  /**
   * @generated from enum value: Day = 12;
   */
  Day = 12,

  /**
   * @generated from enum value: Month = 13;
   */
  Month = 13,

  /**
   * @generated from enum value: Year = 14;
   */
  Year = 14,
}
// Retrieve enum metadata with: proto3.getEnumType(Unit)
proto3.util.setEnumType(Unit, "cloudwhistle.Unit", [
  { no: 0, name: "None" },
  { no: 1, name: "Count" },
  { no: 2, name: "Percent" },
  { no: 3, name: "Byte" },
  { no: 4, name: "Kilobyte" },
  { no: 5, name: "Megabyte" },
  { no: 6, name: "Gigabyte" },
  { no: 7, name: "Terabyte" },
  { no: 8, name: "Millisecond" },
  { no: 9, name: "Second" },
  { no: 10, name: "Minute" },
  { no: 11, name: "Hour" },
  { no: 12, name: "Day" },
  { no: 13, name: "Month" },
  { no: 14, name: "Year" },
]);
