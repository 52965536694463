// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file rule.proto (package cloudwhistle, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Action } from "./action_pb.js";

/**
 * @generated from message cloudwhistle.RuleSet
 */
export class RuleSet extends Message<RuleSet> {
  /**
   * indicates whether this ruleset is NOT actively evaluated
   *
   * @generated from field: bool inactive = 1;
   */
  inactive = false;

  /**
   * list of rules
   *
   * @generated from field: repeated cloudwhistle.Rule rules = 2;
   */
  rules: Rule[] = [];

  /**
   * if true, evaluation of the rules does NOT stop on the first match
   *
   * @generated from field: bool continueProcessing = 3;
   */
  continueProcessing = false;

  /**
   * incidicates whether to continue rule evaluation when a rule errors
   *
   * @generated from field: bool continueOnError = 4;
   */
  continueOnError = false;

  /**
   * name of the ruleset
   *
   * @generated from field: string name = 5;
   */
  name = "";

  /**
   * (optional) arbitrary meta data
   *
   * @generated from field: map<string, string> metadata = 99;
   */
  metadata: { [key: string]: string } = {};

  constructor(data?: PartialMessage<RuleSet>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.RuleSet";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "inactive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "rules", kind: "message", T: Rule, repeated: true },
    { no: 3, name: "continueProcessing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "continueOnError", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    {
      no: 99,
      name: "metadata",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "scalar", T: 9 /* ScalarType.STRING */ },
    },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RuleSet {
    return new RuleSet().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RuleSet {
    return new RuleSet().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RuleSet {
    return new RuleSet().fromJsonString(jsonString, options);
  }

  static equals(
    a: RuleSet | PlainMessage<RuleSet> | undefined,
    b: RuleSet | PlainMessage<RuleSet> | undefined
  ): boolean {
    return proto3.util.equals(RuleSet, a, b);
  }
}

/**
 * select COUNT(*) from resources where timerange=5min and
 *
 * @generated from message cloudwhistle.Rule
 */
export class Rule extends Message<Rule> {
  /**
   * indicates whether this ruleset is NOT actively evaluated
   *
   * @generated from field: bool inactive = 1;
   */
  inactive = false;

  /**
   * time frame to aggregate, in minutes
   *
   * @generated from field: uint32 timeframe = 2;
   */
  timeframe = 0;

  /**
   * index of (named) queries to run on resource data
   *
   * @generated from field: map<string, string> queries = 3;
   */
  queries: { [key: string]: string } = {};

  /**
   * condition to decide whether the rule matches
   *
   * @generated from field: string condition = 4;
   */
  condition = "";

  /**
   * index of action IDs and their parameters; triggered when the rule matches
   *
   * @generated from field: repeated cloudwhistle.RuleAction actions = 5;
   */
  actions: RuleAction[] = [];

  /**
   * indicates whether the evaluation should stop after this matched
   *
   * @generated from field: bool exitOnMatch = 6;
   */
  exitOnMatch = false;

  /**
   * cooldown dictates for how long, in minutes, this rule should not trigger actions again
   *
   * @generated from field: uint32 cooldown = 7;
   */
  cooldown = 0;

  /**
   * (optional) arbitrary meta data
   *
   * @generated from field: map<string, string> metadata = 99;
   */
  metadata: { [key: string]: string } = {};

  constructor(data?: PartialMessage<Rule>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.Rule";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "inactive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "timeframe", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    {
      no: 3,
      name: "queries",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "scalar", T: 9 /* ScalarType.STRING */ },
    },
    { no: 4, name: "condition", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "actions", kind: "message", T: RuleAction, repeated: true },
    { no: 6, name: "exitOnMatch", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "cooldown", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    {
      no: 99,
      name: "metadata",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "scalar", T: 9 /* ScalarType.STRING */ },
    },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Rule {
    return new Rule().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Rule {
    return new Rule().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Rule {
    return new Rule().fromJsonString(jsonString, options);
  }

  static equals(a: Rule | PlainMessage<Rule> | undefined, b: Rule | PlainMessage<Rule> | undefined): boolean {
    return proto3.util.equals(Rule, a, b);
  }
}

/**
 * @generated from message cloudwhistle.RuleAction
 */
export class RuleAction extends Message<RuleAction> {
  /**
   * @generated from field: string templateID = 1;
   */
  templateID = "";

  /**
   * @generated from field: map<string, string> params = 2;
   */
  params: { [key: string]: string } = {};

  constructor(data?: PartialMessage<RuleAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.RuleAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "templateID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    {
      no: 2,
      name: "params",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "scalar", T: 9 /* ScalarType.STRING */ },
    },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RuleAction {
    return new RuleAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RuleAction {
    return new RuleAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RuleAction {
    return new RuleAction().fromJsonString(jsonString, options);
  }

  static equals(
    a: RuleAction | PlainMessage<RuleAction> | undefined,
    b: RuleAction | PlainMessage<RuleAction> | undefined
  ): boolean {
    return proto3.util.equals(RuleAction, a, b);
  }
}

/**
 * @generated from message cloudwhistle.RuleSetEvals
 */
export class RuleSetEvals extends Message<RuleSetEvals> {
  /**
   * @generated from field: repeated cloudwhistle.RuleSetEval evals = 1;
   */
  evals: RuleSetEval[] = [];

  /**
   * did any of the rules match?
   *
   * @generated from field: bool anyMatched = 2;
   */
  anyMatched = false;

  constructor(data?: PartialMessage<RuleSetEvals>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.RuleSetEvals";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "evals", kind: "message", T: RuleSetEval, repeated: true },
    { no: 2, name: "anyMatched", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RuleSetEvals {
    return new RuleSetEvals().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RuleSetEvals {
    return new RuleSetEvals().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RuleSetEvals {
    return new RuleSetEvals().fromJsonString(jsonString, options);
  }

  static equals(
    a: RuleSetEvals | PlainMessage<RuleSetEvals> | undefined,
    b: RuleSetEvals | PlainMessage<RuleSetEvals> | undefined
  ): boolean {
    return proto3.util.equals(RuleSetEvals, a, b);
  }
}

/**
 * @generated from message cloudwhistle.RuleSetEval
 */
export class RuleSetEval extends Message<RuleSetEval> {
  /**
   * list of evaluation results for each rule (that was evaluated)
   *
   * @generated from field: repeated cloudwhistle.RuleEval ruleEvals = 1;
   */
  ruleEvals: RuleEval[] = [];

  /**
   * if an error occurred, this contains the details
   *
   * @generated from field: string errorReason = 2;
   */
  errorReason = "";

  constructor(data?: PartialMessage<RuleSetEval>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.RuleSetEval";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ruleEvals", kind: "message", T: RuleEval, repeated: true },
    { no: 2, name: "errorReason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RuleSetEval {
    return new RuleSetEval().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RuleSetEval {
    return new RuleSetEval().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RuleSetEval {
    return new RuleSetEval().fromJsonString(jsonString, options);
  }

  static equals(
    a: RuleSetEval | PlainMessage<RuleSetEval> | undefined,
    b: RuleSetEval | PlainMessage<RuleSetEval> | undefined
  ): boolean {
    return proto3.util.equals(RuleSetEval, a, b);
  }
}

/**
 * @generated from message cloudwhistle.RuleEval
 */
export class RuleEval extends Message<RuleEval> {
  /**
   * @generated from field: cloudwhistle.Rule rule = 1;
   */
  rule?: Rule;

  /**
   * @generated from field: string ruleID = 2;
   */
  ruleID = "";

  /**
   * @generated from field: bool matched = 3;
   */
  matched = false;

  /**
   * @generated from field: repeated cloudwhistle.Action actions = 4;
   */
  actions: Action[] = [];

  /**
   * if an error occurred, this contains the details
   *
   * @generated from field: string errorReason = 5;
   */
  errorReason = "";

  constructor(data?: PartialMessage<RuleEval>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.RuleEval";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "rule", kind: "message", T: Rule },
    { no: 2, name: "ruleID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "matched", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "actions", kind: "message", T: Action, repeated: true },
    { no: 5, name: "errorReason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RuleEval {
    return new RuleEval().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RuleEval {
    return new RuleEval().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RuleEval {
    return new RuleEval().fromJsonString(jsonString, options);
  }

  static equals(
    a: RuleEval | PlainMessage<RuleEval> | undefined,
    b: RuleEval | PlainMessage<RuleEval> | undefined
  ): boolean {
    return proto3.util.equals(RuleEval, a, b);
  }
}
