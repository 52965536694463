// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file debit.proto (package cloudwhistle, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message cloudwhistle.DebitCalc
 */
export class DebitCalc extends Message<DebitCalc> {
  /**
   * the metric the debit calculation is based on
   *
   * @generated from field: string metric = 1;
   */
  metric = "";

  /**
   * the factors that are multiplied with the metric value
   *
   * @generated from field: repeated cloudwhistle.DebitFactor factors = 2;
   */
  factors: DebitFactor[] = [];

  constructor(data?: PartialMessage<DebitCalc>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.DebitCalc";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metric", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "factors", kind: "message", T: DebitFactor, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DebitCalc {
    return new DebitCalc().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DebitCalc {
    return new DebitCalc().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DebitCalc {
    return new DebitCalc().fromJsonString(jsonString, options);
  }

  static equals(
    a: DebitCalc | PlainMessage<DebitCalc> | undefined,
    b: DebitCalc | PlainMessage<DebitCalc> | undefined
  ): boolean {
    return proto3.util.equals(DebitCalc, a, b);
  }
}

/**
 * @generated from message cloudwhistle.DebitFactor
 */
export class DebitFactor extends Message<DebitFactor> {
  /**
   * @generated from field: string source = 1;
   */
  source = "";

  /**
   * @generated from field: repeated uint32 scale = 2;
   */
  scale: number[] = [];

  constructor(data?: PartialMessage<DebitFactor>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.DebitFactor";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "source", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "scale", kind: "scalar", T: 13 /* ScalarType.UINT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DebitFactor {
    return new DebitFactor().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DebitFactor {
    return new DebitFactor().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DebitFactor {
    return new DebitFactor().fromJsonString(jsonString, options);
  }

  static equals(
    a: DebitFactor | PlainMessage<DebitFactor> | undefined,
    b: DebitFactor | PlainMessage<DebitFactor> | undefined
  ): boolean {
    return proto3.util.equals(DebitFactor, a, b);
  }
}
