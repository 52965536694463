import { ResourceIndex, ResourcesConfig } from "agent-model";
import { useState } from "react";
import { createContainer } from "unstated-next";
import { LoadingState } from "./util";
import pRetry from "p-retry";

function useResources() {
  const [index, setIndex] = useState<ResourceIndex | undefined>(undefined);
  const [loadingState, setLoadingState] = useState(LoadingState.NONE);
  return {
    index,
    loadingState,
    setIndex,
    setLoadingState,
  };
}

function useResourcesConf() {
  const [config, setConfig] = useState<ResourcesConfig | undefined>(undefined);
  const [loadingState, setLoadingState] = useState(LoadingState.NONE);
  return {
    config,
    loadingState,
    setConfig,
    setLoadingState,
  };
}

export async function fetchResources() {
  const response = await pRetry(() => fetch("/api/resources"), {
    minTimeout: 50,
    maxTimeout: 100,
    retries: 3,
  });
  return ResourceIndex.fromJsonString(await response.text());
}

export async function fetchResourcesConf() {
  const response = await pRetry(() => fetch("/api/config/resources"), {
    minTimeout: 50,
    maxTimeout: 100,
    retries: 3,
  });
  return ResourcesConfig.fromJsonString(await response.text());
}

export const Resources = createContainer(useResources);
export const ResourcesConf = createContainer(useResourcesConf);
