import { Button, Card, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";

type RowType = {
  type: string;
  key: string;
};

const columns: ColumnsType<RowType> = [
  {
    title: "Type",
    dataIndex: "type",
    width: "33%",
  },
  {
    title: "Key",
    dataIndex: "key",
    width: "33%",
  },
];

const rows: RowType[] = [
  {
    type: "Email",
    key: "account@domain.com",
  },
];

const Integrations = () => (
  <>
    <div className="flex justify-end mb-6">
      <Tooltip title={"We'll add more integrations like PagerDuty soon!"}>
        <Button type="primary" className="shadow" disabled>
          Add Integration
        </Button>
      </Tooltip>
    </div>

    <Table columns={columns} dataSource={rows} bordered pagination={false} />
  </>
);

export default Integrations;
