let currentTime: Timestamp;
const msPerMinute = 60000;
const start = new Date("2022-10-07:00:00:00.000+00:00").getTime() / msPerMinute;

export type Timestamp = number; // with minute-precision

export const getCurrentTime = (): Timestamp => {
  return currentTime || toTimestamp(new Date());
};

export const setCurrentTime = (d: Date) => {
  currentTime = toTimestamp(d);
};

export const toTimestamp = (d: Date): Timestamp => {
  return Math.floor(d.getTime() / msPerMinute - start);
};

export const toDate = (t: Timestamp): Date => {
  return new Date((t + start) * msPerMinute);
};
