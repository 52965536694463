// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file resource.proto (package cloudwhistle, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Spans } from "./span_pb.js";
import { PricePoint } from "./price_pb.js";
import { Selector } from "./selector_pb.js";
import { Unit } from "./unit_pb.js";

/**
 * @generated from message cloudwhistle.Resource
 */
export class Resource extends Message<Resource> {
  /**
   * (optional) references to other resources (e.g. parents/container)
   *
   * @generated from field: map<string, string> refs = 1;
   */
  refs: { [key: string]: string } = {};

  /**
   * index of specs and their value (e.g. instance_type=t3.medium)
   *
   * @generated from field: map<string, string> specs = 2;
   */
  specs: { [key: string]: string } = {};

  /**
   * timestamp of when the specs were updated last
   *
   * @generated from field: uint32 specsUpdatedAt = 3;
   */
  specsUpdatedAt = 0;

  /**
   * index of metrics and their value
   *
   * @generated from field: map<string, cloudwhistle.Spans> metrics = 4;
   */
  metrics: { [key: string]: Spans } = {};

  /**
   * timestamp of when the metrics were updated last
   *
   * @generated from field: uint32 metricsUpdatedAt = 5;
   */
  metricsUpdatedAt = 0;

  /**
   * index of price dimensions and their price points
   *
   * @generated from field: map<string, cloudwhistle.PricePoint> prices = 6;
   */
  prices: { [key: string]: PricePoint } = {};

  /**
   * timestamp of when the prices were updated last
   *
   * @generated from field: uint32 pricesUpdatedAt = 7;
   */
  pricesUpdatedAt = 0;

  /**
   * index of cost factors and their debits
   *
   * @generated from field: map<string, cloudwhistle.Spans> debits = 8;
   */
  debits: { [key: string]: Spans } = {};

  constructor(data?: PartialMessage<Resource>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.Resource";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: "refs",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "scalar", T: 9 /* ScalarType.STRING */ },
    },
    {
      no: 2,
      name: "specs",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "scalar", T: 9 /* ScalarType.STRING */ },
    },
    { no: 3, name: "specsUpdatedAt", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "metrics", kind: "map", K: 9 /* ScalarType.STRING */, V: { kind: "message", T: Spans } },
    { no: 5, name: "metricsUpdatedAt", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    {
      no: 6,
      name: "prices",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "message", T: PricePoint },
    },
    { no: 7, name: "pricesUpdatedAt", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 8, name: "debits", kind: "map", K: 9 /* ScalarType.STRING */, V: { kind: "message", T: Spans } },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Resource {
    return new Resource().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Resource {
    return new Resource().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Resource {
    return new Resource().fromJsonString(jsonString, options);
  }

  static equals(
    a: Resource | PlainMessage<Resource> | undefined,
    b: Resource | PlainMessage<Resource> | undefined
  ): boolean {
    return proto3.util.equals(Resource, a, b);
  }
}

/**
 * @generated from message cloudwhistle.ResourceTypeIndex
 */
export class ResourceTypeIndex extends Message<ResourceTypeIndex> {
  /**
   * @generated from field: map<string, cloudwhistle.Resource> byName = 1;
   */
  byName: { [key: string]: Resource } = {};

  /**
   * timestamp of when this resource type was last scanned
   *
   * @generated from field: uint32 scannedAt = 2;
   */
  scannedAt = 0;

  constructor(data?: PartialMessage<ResourceTypeIndex>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.ResourceTypeIndex";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "byName", kind: "map", K: 9 /* ScalarType.STRING */, V: { kind: "message", T: Resource } },
    { no: 2, name: "scannedAt", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceTypeIndex {
    return new ResourceTypeIndex().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceTypeIndex {
    return new ResourceTypeIndex().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceTypeIndex {
    return new ResourceTypeIndex().fromJsonString(jsonString, options);
  }

  static equals(
    a: ResourceTypeIndex | PlainMessage<ResourceTypeIndex> | undefined,
    b: ResourceTypeIndex | PlainMessage<ResourceTypeIndex> | undefined
  ): boolean {
    return proto3.util.equals(ResourceTypeIndex, a, b);
  }
}

/**
 * @generated from message cloudwhistle.ResourceServiceIndex
 */
export class ResourceServiceIndex extends Message<ResourceServiceIndex> {
  /**
   * @generated from field: map<string, cloudwhistle.ResourceTypeIndex> byType = 1;
   */
  byType: { [key: string]: ResourceTypeIndex } = {};

  /**
   * timestamp of when this service was updated last
   *
   * @generated from field: uint32 scannedAt = 2;
   */
  scannedAt = 0;

  constructor(data?: PartialMessage<ResourceServiceIndex>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.ResourceServiceIndex";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: "byType",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "message", T: ResourceTypeIndex },
    },
    { no: 2, name: "scannedAt", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceServiceIndex {
    return new ResourceServiceIndex().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceServiceIndex {
    return new ResourceServiceIndex().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceServiceIndex {
    return new ResourceServiceIndex().fromJsonString(jsonString, options);
  }

  static equals(
    a: ResourceServiceIndex | PlainMessage<ResourceServiceIndex> | undefined,
    b: ResourceServiceIndex | PlainMessage<ResourceServiceIndex> | undefined
  ): boolean {
    return proto3.util.equals(ResourceServiceIndex, a, b);
  }
}

/**
 * @generated from message cloudwhistle.ResourceRegionIndex
 */
export class ResourceRegionIndex extends Message<ResourceRegionIndex> {
  /**
   * @generated from field: map<string, cloudwhistle.ResourceServiceIndex> byService = 1;
   */
  byService: { [key: string]: ResourceServiceIndex } = {};

  /**
   * timestamp of when this region was last scanned
   *
   * @generated from field: uint32 scannedAt = 2;
   */
  scannedAt = 0;

  constructor(data?: PartialMessage<ResourceRegionIndex>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.ResourceRegionIndex";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: "byService",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "message", T: ResourceServiceIndex },
    },
    { no: 2, name: "scannedAt", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceRegionIndex {
    return new ResourceRegionIndex().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceRegionIndex {
    return new ResourceRegionIndex().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceRegionIndex {
    return new ResourceRegionIndex().fromJsonString(jsonString, options);
  }

  static equals(
    a: ResourceRegionIndex | PlainMessage<ResourceRegionIndex> | undefined,
    b: ResourceRegionIndex | PlainMessage<ResourceRegionIndex> | undefined
  ): boolean {
    return proto3.util.equals(ResourceRegionIndex, a, b);
  }
}

/**
 * @generated from message cloudwhistle.ResourceIndex
 */
export class ResourceIndex extends Message<ResourceIndex> {
  /**
   * @generated from field: map<string, cloudwhistle.ResourceRegionIndex> byRegion = 1;
   */
  byRegion: { [key: string]: ResourceRegionIndex } = {};

  /**
   * @generated from field: uint32 lastHeartbeatSentAt = 2;
   */
  lastHeartbeatSentAt = 0;

  constructor(data?: PartialMessage<ResourceIndex>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.ResourceIndex";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: "byRegion",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "message", T: ResourceRegionIndex },
    },
    { no: 2, name: "lastHeartbeatSentAt", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceIndex {
    return new ResourceIndex().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceIndex {
    return new ResourceIndex().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceIndex {
    return new ResourceIndex().fromJsonString(jsonString, options);
  }

  static equals(
    a: ResourceIndex | PlainMessage<ResourceIndex> | undefined,
    b: ResourceIndex | PlainMessage<ResourceIndex> | undefined
  ): boolean {
    return proto3.util.equals(ResourceIndex, a, b);
  }
}

/**
 * @generated from message cloudwhistle.ResourceID
 */
export class ResourceID extends Message<ResourceID> {
  /**
   * @generated from field: string region = 1;
   */
  region = "";

  /**
   * @generated from field: string service = 2;
   */
  service = "";

  /**
   * @generated from field: string type = 3;
   */
  type = "";

  /**
   * @generated from field: string name = 4;
   */
  name = "";

  constructor(data?: PartialMessage<ResourceID>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.ResourceID";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "region", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "service", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceID {
    return new ResourceID().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceID {
    return new ResourceID().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceID {
    return new ResourceID().fromJsonString(jsonString, options);
  }

  static equals(
    a: ResourceID | PlainMessage<ResourceID> | undefined,
    b: ResourceID | PlainMessage<ResourceID> | undefined
  ): boolean {
    return proto3.util.equals(ResourceID, a, b);
  }
}

/**
 * @generated from message cloudwhistle.ResourceRequest
 */
export class ResourceRequest extends Message<ResourceRequest> {
  /**
   * Cloud SDK client to use, e.g. `Lambda`
   *
   * @generated from field: string client = 1;
   */
  client = "";

  /**
   * Cloud SDK client function to use
   *
   * @generated from field: string clientFunc = 2;
   */
  clientFunc = "";

  /**
   * @generated from field: string params = 3;
   */
  params = "";

  constructor(data?: PartialMessage<ResourceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.ResourceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "client", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "clientFunc", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "params", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceRequest {
    return new ResourceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceRequest {
    return new ResourceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceRequest {
    return new ResourceRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ResourceRequest | PlainMessage<ResourceRequest> | undefined,
    b: ResourceRequest | PlainMessage<ResourceRequest> | undefined
  ): boolean {
    return proto3.util.equals(ResourceRequest, a, b);
  }
}

/**
 * @generated from message cloudwhistle.ResourceListLookup
 */
export class ResourceListLookup extends Message<ResourceListLookup> {
  /**
   * @generated from field: cloudwhistle.ResourceRequest request = 1;
   */
  request?: ResourceRequest;

  /**
   * @generated from field: cloudwhistle.Selector listSelector = 2;
   */
  listSelector?: Selector;

  /**
   * @generated from field: cloudwhistle.Selector nameSelector = 3;
   */
  nameSelector?: Selector;

  constructor(data?: PartialMessage<ResourceListLookup>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.ResourceListLookup";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request", kind: "message", T: ResourceRequest },
    { no: 2, name: "listSelector", kind: "message", T: Selector },
    { no: 3, name: "nameSelector", kind: "message", T: Selector },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceListLookup {
    return new ResourceListLookup().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceListLookup {
    return new ResourceListLookup().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceListLookup {
    return new ResourceListLookup().fromJsonString(jsonString, options);
  }

  static equals(
    a: ResourceListLookup | PlainMessage<ResourceListLookup> | undefined,
    b: ResourceListLookup | PlainMessage<ResourceListLookup> | undefined
  ): boolean {
    return proto3.util.equals(ResourceListLookup, a, b);
  }
}

/**
 * @generated from message cloudwhistle.ResourceDetailLookup
 */
export class ResourceDetailLookup extends Message<ResourceDetailLookup> {
  /**
   * @generated from field: cloudwhistle.ResourceRequest request = 1;
   */
  request?: ResourceRequest;

  /**
   * @generated from field: cloudwhistle.Selector listSelector = 2;
   */
  listSelector?: Selector;

  /**
   * @generated from field: cloudwhistle.Selector inputNameSelector = 3;
   */
  inputNameSelector?: Selector;

  /**
   * @generated from field: string inputNameParam = 4;
   */
  inputNameParam = "";

  /**
   * @generated from field: cloudwhistle.Selector outputNameSelector = 5;
   */
  outputNameSelector?: Selector;

  /**
   * @generated from field: uint32 batchLimit = 6;
   */
  batchLimit = 0;

  /**
   * @generated from field: map<string, cloudwhistle.Selector> select = 8;
   */
  select: { [key: string]: Selector } = {};

  /**
   * transient field
   *
   * @generated from field: repeated string names = 99;
   */
  names: string[] = [];

  constructor(data?: PartialMessage<ResourceDetailLookup>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.ResourceDetailLookup";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request", kind: "message", T: ResourceRequest },
    { no: 2, name: "listSelector", kind: "message", T: Selector },
    { no: 3, name: "inputNameSelector", kind: "message", T: Selector },
    { no: 4, name: "inputNameParam", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "outputNameSelector", kind: "message", T: Selector },
    { no: 6, name: "batchLimit", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 8, name: "select", kind: "map", K: 9 /* ScalarType.STRING */, V: { kind: "message", T: Selector } },
    { no: 99, name: "names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceDetailLookup {
    return new ResourceDetailLookup().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceDetailLookup {
    return new ResourceDetailLookup().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceDetailLookup {
    return new ResourceDetailLookup().fromJsonString(jsonString, options);
  }

  static equals(
    a: ResourceDetailLookup | PlainMessage<ResourceDetailLookup> | undefined,
    b: ResourceDetailLookup | PlainMessage<ResourceDetailLookup> | undefined
  ): boolean {
    return proto3.util.equals(ResourceDetailLookup, a, b);
  }
}

/**
 * @generated from message cloudwhistle.ResourceUsageLookup
 */
export class ResourceUsageLookup extends Message<ResourceUsageLookup> {
  /**
   * the code to lookup the metric
   *
   * @generated from field: string metricCode = 1;
   */
  metricCode = "";

  /**
   * identifiers to filter metrics for this resource type by resource name
   *
   * @generated from field: map<string, string> queryParams = 2;
   */
  queryParams: { [key: string]: string } = {};

  /**
   * an (optional) query expression to modify the metric value
   *
   * @generated from field: string query = 3;
   */
  query = "";

  /**
   * the unit, if any
   *
   * @generated from field: cloudwhistle.Unit unit = 4;
   */
  unit = Unit.None;

  constructor(data?: PartialMessage<ResourceUsageLookup>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.ResourceUsageLookup";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metricCode", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    {
      no: 2,
      name: "queryParams",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "scalar", T: 9 /* ScalarType.STRING */ },
    },
    { no: 3, name: "query", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "unit", kind: "enum", T: proto3.getEnumType(Unit) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceUsageLookup {
    return new ResourceUsageLookup().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceUsageLookup {
    return new ResourceUsageLookup().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceUsageLookup {
    return new ResourceUsageLookup().fromJsonString(jsonString, options);
  }

  static equals(
    a: ResourceUsageLookup | PlainMessage<ResourceUsageLookup> | undefined,
    b: ResourceUsageLookup | PlainMessage<ResourceUsageLookup> | undefined
  ): boolean {
    return proto3.util.equals(ResourceUsageLookup, a, b);
  }
}
