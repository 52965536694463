import React from "react";
import ReactDOM from "react-dom/client";
import { Resources, ResourcesConf } from "./store/resources";
import { useRoutes, BrowserRouter } from "react-router-dom";

import "./index.css";
import "antd/dist/antd.less";

import Layout from "./layout";
import Home from "./pages/live";
import Rules from "./pages/rules";
import NotFound from "./pages/notfound";
import Setup from "./pages/setup";
import Settings from "./pages/settings";
import Accounts from "./pages/accounts";
import Integrations from "./pages/integrations";

if (process.env.NODE_ENV === "development") {
  const { worker } = await import("./store/msw");
  worker.start();
}

function Router() {
  return useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: "/setup",
          element: <Setup />,
        },
        {
          path: "/rules",
          element: <Rules />,
        },
        {
          path: "/accounts",
          element: <Accounts />,
        },
        {
          path: "/accounts",
          element: <Settings />,
        },
        {
          path: "/integrations",
          element: <Integrations />,
        },
        {
          path: "/settings",
          element: <Settings />,
        },
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
  ]);
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Resources.Provider>
      <ResourcesConf.Provider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </ResourcesConf.Provider>
    </Resources.Provider>
  </React.StrictMode>
);
