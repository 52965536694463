// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file span.proto (package cloudwhistle, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message cloudwhistle.Span
 */
export class Span extends Message<Span> {
  /**
   * @generated from field: repeated uint32 timestamps = 1;
   */
  timestamps: number[] = [];

  /**
   * @generated from field: repeated float values = 2;
   */
  values: number[] = [];

  constructor(data?: PartialMessage<Span>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.Span";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timestamps", kind: "scalar", T: 13 /* ScalarType.UINT32 */, repeated: true },
    { no: 2, name: "values", kind: "scalar", T: 2 /* ScalarType.FLOAT */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Span {
    return new Span().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Span {
    return new Span().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Span {
    return new Span().fromJsonString(jsonString, options);
  }

  static equals(a: Span | PlainMessage<Span> | undefined, b: Span | PlainMessage<Span> | undefined): boolean {
    return proto3.util.equals(Span, a, b);
  }
}

/**
 * @generated from message cloudwhistle.Spans
 */
export class Spans extends Message<Spans> {
  /**
   * index of spans by range (ie total time covered by span)
   *
   * @generated from field: map<string, cloudwhistle.Span> byScope = 1;
   */
  byScope: { [key: string]: Span } = {};

  constructor(data?: PartialMessage<Spans>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.Spans";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "byScope", kind: "map", K: 9 /* ScalarType.STRING */, V: { kind: "message", T: Span } },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Spans {
    return new Spans().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Spans {
    return new Spans().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Spans {
    return new Spans().fromJsonString(jsonString, options);
  }

  static equals(
    a: Spans | PlainMessage<Spans> | undefined,
    b: Spans | PlainMessage<Spans> | undefined
  ): boolean {
    return proto3.util.equals(Spans, a, b);
  }
}

/**
 * @generated from message cloudwhistle.SpanScope
 */
export class SpanScope extends Message<SpanScope> {
  /**
   * indicates whether this scope is NOT actively updated
   *
   * @generated from field: bool inactive = 1;
   */
  inactive = false;

  /**
   * the time, in minutes, each entry in a span represents
   *
   * @generated from field: uint32 interval = 2;
   */
  interval = 0;

  /**
   * the maximum number of entries in a span
   *
   * @generated from field: uint32 limit = 3;
   */
  limit = 0;

  constructor(data?: PartialMessage<SpanScope>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.SpanScope";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "inactive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "interval", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "limit", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SpanScope {
    return new SpanScope().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SpanScope {
    return new SpanScope().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SpanScope {
    return new SpanScope().fromJsonString(jsonString, options);
  }

  static equals(
    a: SpanScope | PlainMessage<SpanScope> | undefined,
    b: SpanScope | PlainMessage<SpanScope> | undefined
  ): boolean {
    return proto3.util.equals(SpanScope, a, b);
  }
}
