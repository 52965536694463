import {
  getCurrentTime,
  idxForEach,
  toString,
  Resource,
  ResourceIndex,
  Span,
  SpanScope,
  ResourceID,
} from "agent-model";
import { forEach } from "lodash";

export type ResourceIndexSummary = {
  total: ResourceSummary;
  [id: string]: ResourceSummary;
};

export type ResourceSummary = {
  id?: ResourceID;
  scopes: {
    [scope: string]: ResourceScopeSummary;
  };
};

export type ResourceScopeSummary = {
  total: number;
  history: number[];
};

export function summary(idx: ResourceIndex, scopes: Record<string, SpanScope>) {
  const res: ResourceIndexSummary = {
    total: emptySum(scopes),
  };

  idxForEach(idx, (id, resource) => {
    const sum = { id, ...sumResource(resource, scopes) };
    res[toString(id)] = sum;

    forEach(scopes, (_spanScope, scope) => {
      res.total.scopes[scope].total += sum.scopes[scope].total;
      forEach(sum.scopes[scope].history, (val, i) => {
        res.total.scopes[scope].history[i] += val;
      });
    });
  });

  return res;
}

function sumResource(resource: Resource, scopes: Record<string, SpanScope>) {
  const res = emptySum(scopes);
  forEach(resource.debits, (spans) => {
    forEach(spans.byScope, (span, scope) => {
      const spanScope = scopes[scope];
      addSpan(span, res.scopes[scope], spanScope);
    });
  });
  return res;
}

function emptySum(scopes: Record<string, SpanScope>) {
  const res: ResourceSummary = { scopes: {} };
  for (const scope in scopes) {
    const { limit } = scopes[scope];
    res.scopes[scope] = {
      total: 0,
      history: Array.from(Array(limit).keys()).map(() => 0),
    };
  }
  return res;
}

function addSpan(span: Span, summary: ResourceScopeSummary, scope: SpanScope) {
  const rangeStart = getCurrentTime();
  const rangeEnd = rangeStart - scope.interval * scope.limit;

  let windowStart = rangeStart;
  let windowEnd = rangeStart - scope.interval;

  let spanIdx = 0;
  let summaryIdx = 0;
  while (windowStart > rangeEnd) {
    while (spanIdx < span.timestamps.length) {
      const cur = span.timestamps[spanIdx];
      if (cur < windowEnd) {
        break; // we've exceeded the window, stop
      }
      if (cur <= windowStart) {
        // we're inside the window; add it up!
        const val = span.values[spanIdx];
        summary.total += val;
        summary.history[summaryIdx] += val;
      }
      spanIdx += 1;
    }

    // move window back in time
    windowStart -= scope.interval;
    windowEnd -= scope.interval;
    summaryIdx += 1;
  }
}
