import { Card, Col, Row, Statistic } from "antd";
import { asMoney } from "../../util/format";
import { Chart } from "./chart";

function Summary({
  ready,
  total_1h,
  total_1h_values,
  total_24h,
  total_24h_values,
}: {
  ready: boolean;
  total_1h: number;
  total_1h_values: number[];
  total_24h: number;
  total_24h_values: number[];
}) {
  return (
    <div>
      <Row gutter={28}>
        <Col span={12}>
          <Card className="shadow">
            <Row>
              <Col span={12}>
                <Statistic
                  title={"Cost over the last hour"}
                  loading={!ready}
                  value={asMoney(total_1h)}
                  valueStyle={{ fontWeight: "bold" }}
                  precision={2}
                  prefix="$"
                />
              </Col>
              <Col span={12}>
                <Chart data={total_1h_values} interval={5} height={50} />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={12}>
          <Card className="shadow">
            <Row>
              <Col span={12}>
                <Statistic
                  title={"Cost over the last 24 hours"}
                  loading={!ready}
                  value={asMoney(total_24h)}
                  valueStyle={{ fontWeight: "bold" }}
                  prefix="$"
                />
              </Col>
              <Col span={12}>
                <Chart data={total_24h_values} interval={60} height={50} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Summary;
