// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file selector.proto (package cloudwhistle, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Unit } from "./unit_pb.js";

/**
 * @generated from message cloudwhistle.Selector
 */
export class Selector extends Message<Selector> {
  /**
   * the query to select the data, e.g. 'a[0].b.c'
   *
   * @generated from field: string query = 1;
   */
  query = "";

  /**
   * (optional) the default value in case the query result is empty
   *
   * @generated from field: string default = 2;
   */
  default = "";

  /**
   * (optional) the unit of the selected data
   *
   * @generated from field: cloudwhistle.Unit unit = 3;
   */
  unit = Unit.None;

  /**
   * (optional) how to scale the numeric data
   *
   * @generated from field: uint32 scale = 4;
   */
  scale = 0;

  /**
   * @generated from field: repeated cloudwhistle.SelectorCases cases = 5;
   */
  cases: SelectorCases[] = [];

  constructor(data?: PartialMessage<Selector>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.Selector";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "query", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "default", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "unit", kind: "enum", T: proto3.getEnumType(Unit) },
    { no: 4, name: "scale", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "cases", kind: "message", T: SelectorCases, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Selector {
    return new Selector().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Selector {
    return new Selector().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Selector {
    return new Selector().fromJsonString(jsonString, options);
  }

  static equals(
    a: Selector | PlainMessage<Selector> | undefined,
    b: Selector | PlainMessage<Selector> | undefined
  ): boolean {
    return proto3.util.equals(Selector, a, b);
  }
}

/**
 * @generated from message cloudwhistle.SelectorCases
 */
export class SelectorCases extends Message<SelectorCases> {
  /**
   * regex to indicate whether the case applies
   *
   * @generated from field: string when = 1;
   */
  when = "";

  /**
   * value expression to use
   *
   * @generated from field: string then = 2;
   */
  then = "";

  constructor(data?: PartialMessage<SelectorCases>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.SelectorCases";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "when", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "then", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SelectorCases {
    return new SelectorCases().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SelectorCases {
    return new SelectorCases().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SelectorCases {
    return new SelectorCases().fromJsonString(jsonString, options);
  }

  static equals(
    a: SelectorCases | PlainMessage<SelectorCases> | undefined,
    b: SelectorCases | PlainMessage<SelectorCases> | undefined
  ): boolean {
    return proto3.util.equals(SelectorCases, a, b);
  }
}
