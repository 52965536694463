// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file alert.proto (package cloudwhistle, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message cloudwhistle.AlertState
 */
export class AlertState extends Message<AlertState> {
  /**
   * @generated from field: map<string, cloudwhistle.Alert> alerts = 1;
   */
  alerts: { [key: string]: Alert } = {};

  constructor(data?: PartialMessage<AlertState>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.AlertState";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "alerts", kind: "map", K: 9 /* ScalarType.STRING */, V: { kind: "message", T: Alert } },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AlertState {
    return new AlertState().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AlertState {
    return new AlertState().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AlertState {
    return new AlertState().fromJsonString(jsonString, options);
  }

  static equals(
    a: AlertState | PlainMessage<AlertState> | undefined,
    b: AlertState | PlainMessage<AlertState> | undefined
  ): boolean {
    return proto3.util.equals(AlertState, a, b);
  }
}

/**
 * @generated from message cloudwhistle.Alert
 */
export class Alert extends Message<Alert> {
  /**
   * @generated from field: uint32 triggeredAt = 1;
   */
  triggeredAt = 0;

  /**
   * @generated from field: uint32 cooldown = 2;
   */
  cooldown = 0;

  constructor(data?: PartialMessage<Alert>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.Alert";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "triggeredAt", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "cooldown", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Alert {
    return new Alert().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Alert {
    return new Alert().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Alert {
    return new Alert().fromJsonString(jsonString, options);
  }

  static equals(
    a: Alert | PlainMessage<Alert> | undefined,
    b: Alert | PlainMessage<Alert> | undefined
  ): boolean {
    return proto3.util.equals(Alert, a, b);
  }
}
