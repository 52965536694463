import { AreaChart, Area, ResponsiveContainer, Tooltip } from "recharts";
import { asMoney } from "../../util/format";

export const Chart = ({ data, interval, height }: { data: number[]; interval: number; height: number }) => {
  const count = data.length;
  return (
    <ResponsiveContainer width="100%" height={height}>
      <AreaChart
        data={data.map((v) => ({ v: v > 0 ? asMoney(v) : 0 }))}
        height={height}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <defs>
          <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="1%" stopColor="#9333EA" stopOpacity={0.2} />
            <stop offset="100%" stopColor="#fff" stopOpacity={0.6} />
          </linearGradient>
        </defs>
        <Tooltip
          formatter={(v) => {
            return "$" + v;
          }}
          labelFormatter={(x) => {
            const time = Math.ceil((count - x) * interval);
            if (time === 60) {
              return `1 hour ago`;
            } else if (time >= 60) {
              return `${time / 60} hours ago`;
            } else {
              return `${time} minutes ago`;
            }
          }}
        />
        <Area type="monotone" dataKey="v" stroke="#9333EA" fill="url(#gradient)" />
      </AreaChart>
    </ResponsiveContainer>
  );
};
